@import url("https://fonts.googleapis.com/css?family=Space+Mono:400,700");

// These are the defaults, but you can override any values
$font-family-sans-serif: "Space Mono", -apple-system, system-ui, BlinkMacSystemFont,
  "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif !default;
$font-family-base: $font-family-sans-serif !default;

// $body-bg: #efefef;

// $theme-colors: (
//   "primary": #00ff83,
//   "light": #efefef,
//   "dark": #1f434d,
//   "third": yellow,
//   "fourth": magenta,
// );

// https://getbootstrap.com/docs/4.3/content/typography/#responsive-font-sizes
$enable-responsive-font-sizes: true;


// Bootstrap and its default variables
@import "../node_modules/bootstrap/scss/bootstrap";
